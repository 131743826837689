@font-face {
  font-family: Geom;
  src: url(./Geom-Bold.otf);
}

body {
  margin: 0;
  font-family: 'Geom';
  background-color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #333;
}

.whitelist-box {
  background-color: #f1f1f1;
  padding: 30px 50px 50px 50px;
  text-align: center;
  border-radius: 40px;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
  flex: 1;
  margin-bottom: 120px;
  z-index: 999;
}

h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 30px;
  color: #0052FF;
}

.input-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #e8e8e8;
  border-radius: 5px;
  margin-right: 10px;
  flex: 1;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
  font-family: "Geom";
}

.header {
  position: fixed;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
  background-color: #f1f1f1;
  border-radius: 15px;
  top: 50px;
  left: 30px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

img.logo {
  width: 200px;
}

button {
  color: white;
  padding: 0.7em 1.7em;
  font-size: 18px;
  font-weight: 700;
  border-radius: 0.5em;
  background: #0052FF;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

button:hover {
  border: 1px solid white;
}

button:active {
  box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
}

.check-button {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 2px;
  font-family: "Geom";
}

.paste-button {
  padding: 10px 20px;
  margin-left: 5px;
  font-family: "Geom";
}

.fixed-image {
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  z-index: 1;
}

.footer {
  position: fixed;
  bottom: 10px;
  left: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #f1f1f1;
  border-radius: 15px;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
  z-index: 2;
}

.footer p {
  margin: 0;
}

.footer-dpunks {
  display: block;
}

.twitter-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.twitter-icon {
  font-size: 24px;
  color: #1DA1F2;
  transition: color 0.3s;
}

.twitter-icon:hover {
  color: #0d95e8;
}

@media (max-width: 600px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  input[type="text"] {
    font-size: 0.9rem;
    padding: 8px;
  }

  button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 1000px) {
  .fixed-image {
    width: 80%;
    bottom: 100px;
  }

  .whitelist-box {
    margin-bottom: 0;
  }
}

@media (max-width: 400px) {
  .paste-button {
    display: none;
  }

  input[type="text"] {
    margin-right: 0;
  }

  .fixed-image {
    width: 80%;
    bottom: 120px;
  }
}
