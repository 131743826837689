body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #e6e6e6;
  overflow: hidden;
}

.background span {
  width: 13vmin;
  height: 13vmin;
  border-radius: 13vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 27;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: #FFACAC;
  top: 54%;
  left: 17%;
  animation-duration: 50s;
  animation-delay: -18s;
  transform-origin: -10vw -6vh;
  box-shadow: 26vmin 0 3.9754629952241127vmin currentColor;
}
.background span:nth-child(1) {
  color: #E45A84;
  top: 32%;
  left: 31%;
  animation-duration: 31s;
  animation-delay: -112s;
  transform-origin: -10vw -21vh;
  box-shadow: -26vmin 0 3.7073306057985613vmin currentColor;
}
.background span:nth-child(2) {
  color: #E45A84;
  top: 58%;
  left: 82%;
  animation-duration: 19s;
  animation-delay: -107s;
  transform-origin: 24vw 8vh;
  box-shadow: 26vmin 0 3.7405725929867497vmin currentColor;
}
.background span:nth-child(3) {
  color: #E45A84;
  top: 24%;
  left: 100%;
  animation-duration: 94s;
  animation-delay: -91s;
  transform-origin: -17vw -19vh;
  box-shadow: 26vmin 0 3.513272939170579vmin currentColor;
}
.background span:nth-child(4) {
  color: #583C87;
  top: 30%;
  left: 42%;
  animation-duration: 123s;
  animation-delay: -134s;
  transform-origin: 24vw 6vh;
  box-shadow: -26vmin 0 3.5378691110430003vmin currentColor;
}
.background span:nth-child(5) {
  color: #583C87;
  top: 72%;
  left: 66%;
  animation-duration: 186s;
  animation-delay: -209s;
  transform-origin: 7vw -14vh;
  box-shadow: -26vmin 0 3.936388042398667vmin currentColor;
}
.background span:nth-child(6) {
  color: #FFACAC;
  top: 31%;
  left: 32%;
  animation-duration: 70s;
  animation-delay: -163s;
  transform-origin: 22vw -22vh;
  box-shadow: -26vmin 0 4.22668267753491vmin currentColor;
}
.background span:nth-child(7) {
  color: #583C87;
  top: 100%;
  left: 91%;
  animation-duration: 120s;
  animation-delay: -159s;
  transform-origin: -3vw 20vh;
  box-shadow: -26vmin 0 3.921353593758602vmin currentColor;
}
.background span:nth-child(8) {
  color: #583C87;
  top: 55%;
  left: 54%;
  animation-duration: 91s;
  animation-delay: -190s;
  transform-origin: 21vw 22vh;
  box-shadow: -26vmin 0 3.616455834134732vmin currentColor;
}
.background span:nth-child(9) {
  color: #583C87;
  top: 92%;
  left: 64%;
  animation-duration: 219s;
  animation-delay: -87s;
  transform-origin: -5vw 10vh;
  box-shadow: 26vmin 0 3.7105536994433113vmin currentColor;
}
.background span:nth-child(10) {
  color: #E45A84;
  top: 66%;
  left: 91%;
  animation-duration: 175s;
  animation-delay: -158s;
  transform-origin: 5vw -14vh;
  box-shadow: 26vmin 0 4.140651856415127vmin currentColor;
}
.background span:nth-child(11) {
  color: #583C87;
  top: 88%;
  left: 55%;
  animation-duration: 234s;
  animation-delay: -140s;
  transform-origin: 24vw 3vh;
  box-shadow: -26vmin 0 3.9323041288411384vmin currentColor;
}
.background span:nth-child(12) {
  color: #583C87;
  top: 46%;
  left: 30%;
  animation-duration: 67s;
  animation-delay: -118s;
  transform-origin: -13vw 15vh;
  box-shadow: -26vmin 0 3.973853503427293vmin currentColor;
}
.background span:nth-child(13) {
  color: #FFACAC;
  top: 35%;
  left: 22%;
  animation-duration: 26s;
  animation-delay: -139s;
  transform-origin: 1vw -18vh;
  box-shadow: 26vmin 0 4.063052094869944vmin currentColor;
}
.background span:nth-child(14) {
  color: #FFACAC;
  top: 4%;
  left: 47%;
  animation-duration: 215s;
  animation-delay: -158s;
  transform-origin: 8vw 23vh;
  box-shadow: 26vmin 0 3.406009607510509vmin currentColor;
}
.background span:nth-child(15) {
  color: #FFACAC;
  top: 3%;
  left: 18%;
  animation-duration: 155s;
  animation-delay: -93s;
  transform-origin: 16vw -18vh;
  box-shadow: 26vmin 0 3.442922695635183vmin currentColor;
}
.background span:nth-child(16) {
  color: #FFACAC;
  top: 68%;
  left: 64%;
  animation-duration: 216s;
  animation-delay: -26s;
  transform-origin: -3vw 16vh;
  box-shadow: 26vmin 0 3.551452988673831vmin currentColor;
}
.background span:nth-child(17) {
  color: #E45A84;
  top: 55%;
  left: 32%;
  animation-duration: 209s;
  animation-delay: -103s;
  transform-origin: 6vw 4vh;
  box-shadow: 26vmin 0 3.563461465887359vmin currentColor;
}
.background span:nth-child(18) {
  color: #E45A84;
  top: 79%;
  left: 10%;
  animation-duration: 120s;
  animation-delay: -64s;
  transform-origin: 21vw 10vh;
  box-shadow: -26vmin 0 4.168616025615533vmin currentColor;
}
.background span:nth-child(19) {
  color: #FFACAC;
  top: 65%;
  left: 99%;
  animation-duration: 61s;
  animation-delay: -59s;
  transform-origin: 18vw 25vh;
  box-shadow: -26vmin 0 3.501047894840936vmin currentColor;
}
